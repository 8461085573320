import { SectionTemplate } from "./SectionTemplate";
import { Row, Col } from "react-bootstrap";

export const ContactoNoForm = () => {
  return (
    <>
      <div id="contacto"></div>
      <SectionTemplate
        title="CONTACTO"
        direction="right"
        textDirection="text-end"
      >
        <section className="mx-3">
          <h3 className="my-4 text-center">¿Cómo podemos ayudarte?</h3>
          <p className="text__p mt-4 text-center mb-5">
            Puedes contactar con nosotros a través de
            <a
              href="mailto:medios@grupomayo.com"
              className="mx-2 text__p color-red"
              target="_blank"
              rel="noreferrer"
            >
              medios@grupomayo.com
            </a>
          </p>
          {/* <Row>
            <Col md={6} className="text-end px-4 my-2 border-left">
              <h3 className="color-red">Barcelona</h3>
              <p className="fs-4">
                Aribau, 185-187, 2ª planta
                <br />
                932 090 255
              </p>
              <p className="fs-4 fw-bold">Mónica Sáez</p>
              <div className="d-flex justify-content-end mb-2">
            
                <a href="mailto:msaez@grupomayo.com" className="mx-2 " target="_blank" rel="noreferrer">
                  {" "}
                  msaez@grupomayo.com
                </a>
                  <img
                    src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/mail.svg"
                    alt=""
                    width="30"
                  />
               
              </div>
              <div className="d-flex justify-content-end ">
               
                  <div className="mx-2 fs-5 fw-bold"> 629 342 051</div>
                  <img
                    src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/movil.svg"
                    alt=""
                    width="30"
                  />
                
              </div>
            </Col>
            <Col md={6} className="text-start px-4 my-2">
              <h3 className="color-red">Madrid</h3>
              <p className="fs-4">
               Méndez Álvaro, 20, despacho 520
                <br />
                914 115 800
              </p>
              <p className="fs-4 fw-bold">Raquel Morán</p>
              <div className="d-flex mb-2">
               
                  <img
                    src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/mail.svg"
                    alt=""
                    width="30"
                  />
               
                <a href="mailto:raquelmoran@grupomayo.com" className="mx-2 " target="_blank" rel="noreferrer">
                  {" "}
                  raquelmoran@grupomayo.com
                </a>
              </div>
              <div className="d-flex">
                <div className="w-img">
                  <img
                    src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/movil.svg"
                    alt=""
                    width="30"
                  />
                </div>
                <div className="mx-2 fs-5 fw-bold"> 646 475 0311</div>
              </div>
            </Col>
          </Row> */}
        </section>
      </SectionTemplate>
    </>
  );
};
