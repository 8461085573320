// import logo from './logo.svg';

import { Header } from "./components/Header";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ScrollToTop from "react-scroll-to-top";
import { VideoTop } from "./components/VideoTop";
import { Ecosistema } from "./components/Ecosistema";
import { Cifras } from "./components/Cifras";
import { Notoriedad } from "./components/Notoriedad";
import { Posicionamiento } from "./components/Posicionamiento";
import { PackTematico } from "./components/PackTematico";
import { Especiales } from "./components/Especiales";
import { Relevancia } from "./components/Relevancia";
import { Farmaceutico } from "./components/Farmaceutico";
import { Contacto } from "./components/Contacto";
import { Footer } from "./components/Footer";
import { ContactoNoForm } from "./components/ContactoNoForm";

function App() {
  return (
    <>
      <ScrollToTop smooth color="#b20e10" />
      <Header />
      <VideoTop />
      <Cifras />
      <Ecosistema />
      <Notoriedad />
      <Posicionamiento />
      <PackTematico />
      <Especiales />
      <Relevancia />
      <Farmaceutico />
      {/* <Contacto /> */}
      <ContactoNoForm/>
      <Footer />
    </>
  );
}

export default App;
