import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const PerfilesModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Ver perfil de usuarios
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3 className="color-red">Perfil de usuarios</h3>
          <section className="row">
            <div className="col-md-6 align-self-start mt-md-5">
              
              <div className="p__modal ">
                El perfil de nuestros usuarios representa la realidad del
                profesional farmacéutico en España buscando asegurar también la lectura de los futuros
                farmacéuticos del país
              </div>
            </div>
            <div className="col-md-6 text-center ">
              <img
                src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/grafic1.svg"
                alt="Hombres/Mujeres"
                className="img-fluid mt-3"
                width="220"
              />
              <div className="font-small mt-4 mb-5 mx-5">
                <span className="fw-bold">
                  Perfil de usuarios de El Farmacéutico{" "}
                </span>
                (sexo)
              </div>
            </div>
            <div className="col-md-12 text-center ">
              <img
                src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/grafic2.svg"
                alt="Farmaceuticos"
                className="img-fluid"
                width="500"
              />
              <div className="font-small mt-3 mb-4">
                <span className="fw-bold me-1 mx-4 ">
                  Perfil de usuarios de El Farmacéutico vs. farmacéuticos
                  colegiados
                </span>
                (edad en años)
              </div>
            </div>
            
          </section>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
