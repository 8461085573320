import React from "react";
import { Navigation } from "./Navigation";

export const Header = () => {
  return (
    <header className="header px-5 ">
      <Navigation />
    </header>
  );
};
