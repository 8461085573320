import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { CardRevistaTemplate } from "./CardRevistaTemplate";
import useScreenSize from "../hooks/useScreenSize";
const revistas = [
  {
    id: 1,
    numero: "N.º 617",
    mes: "Enero",
    contenido: [
      "Resfriado y gripe",
      "Deshabituación tabáquica",
      "Claves en dermofarmacia: Introducción",
    ],
    tituloCurso: "Curso Seguimiento Farmacoterapéutico",
    curso:
      "Módulo I. Introducción al servicio de seguimiento farmacoterapéutico",
    consejo: ["Desánimo"],
    dia: null,
    fecha: null,
  },
  {
    id: 2,
    numero: "N.º 618",
    mes: "Febrero",
    contenido: ["Salud de garganta y salud bucal", "Incontinencia"],
    tituloCurso: "Curso Seguimiento Farmacoterapéutico ",
    curso: "Módulo II. Ofrecimiento del servicio y primera cita",
    consejo: ["Hiperlipidemia"],
    dia: "Día Europeo de la Salud Sexual ",
    fecha: "14 de febrero",
  },
  {
    id: 3,
    numero: "N.º 619",
    mes: "Marzo",
    contenido: [
      "INFARMA: entrevistas",
      "Afecciones del sueño",
      "Alergias",
      "Claves en dermofarmacia: Pieles atópicas",
    ],
    tituloCurso: "Curso Seguimiento Farmacoterapéutico ",
    curso: "Módulo III. Primera entrevista",
    consejo: ["Rinitis"],
    dia: "Día Mundial del Sueño ",
    fecha: "19 de marzo",
  },
  {
    id: 4,
    numero: "N.º 620",
    mes: "Abril",
    contenido: ["Síndrome del intestino irritable ", "Salud íntima", "Acidez "],
    tituloCurso: "Curso Seguimiento Farmacoterapéutico ",
    curso: "Módulo IV. Fase de estudio del plan terapéutico del paciente",
    consejo: ["Probióticos y prebióticos"],
    dia: "Día Mundial de la Voz ",
    fecha: "16 de abril",
  },
  {
    id: 5,
    numero: "N.º 621",
    mes: "Mayo",
    contenido: [
      "Menopausia",
      "Deporte y salud articular",
      "Claves en dermofarmacia: Solares",
    ],
    tituloCurso: "Curso Seguimiento Farmacoterapéutico ",
    curso: "Módulo V. Evaluación: plan de actuación y objetivos prioritarios",
    consejo: ["Piernas cansadas"],
    dia: "Día Mundial sin Tabaco ",
    fecha: "31 de mayo",
  },
  {
    id: 6,
    numero: "N.º 622",
    mes: "Junio",
    contenido: [
      "Bienestar intestinal (disbiosis)",
      "Probióticos y prebióticos",
      "Micronutrientes",
    ],
    tituloCurso: "Curso Seguimiento Farmacoterapéutico ",
    curso:
      "Módulo VI. Plan de actuación: intervenciones farmacológicas y no farmacológicas",
    consejo: ["Celulitis"],
    dia: "Día Mundial de la Microbiota Intestinal",
    fecha: "27 de junio",
  },
  {
    id: 7,
    numero: "N.º 623",
    mes: "Julio",
    contenido: [
      "Protección ocular",
      "Prevención de la cistitis",
      "Diarrea del viajero",
      "Claves en dermofarmacia: Envejecimiento",
    ],
    tituloCurso: "Curso Seguimiento Farmacoterapéutico ",
    curso: "Módulo VII. Entrevistas sucesivas. Resultado de la intervención ",
    consejo: ["Picaduras"],
    dia: "Día Mundial de la Alergia",
    fecha: "8 de julio",
  },
  {
    id: 8,
    numero: "N.º 624",
    mes: "Septiembre",
    contenido: [
      "Chinches, pulgas y bichos varios",
      "Depresión posvacacional",
      "Claves en dermofarmacia: Acné y rosácea",
    ],
    tituloCurso: "Curso Seguimiento Farmacoterapéutico ",
    curso: "Módulo VIII. Nuevas intervenciones y valoración de resultados",
    consejo: ["Piojos"],
    dia: "Día Mundial del Farmacéutico",
    fecha: "25 de septiembre",
  },
  {
    id: 9,
    numero: "N.º 625",
    mes: "Octubre",
    contenido: [
      "Adolescentes y salud mental",
      "Defensas para afrontar el invierno",
      "Claves en dermofarmacia: Salud capilar",
    ],
    tituloCurso: null,
    curso: null,
    consejo: ["Ojo seco"],
    dia: "Día Mundial de la Visión",
    fecha: "13 de octubre",
  },
  {
    id: 10,
    numero: "N.º 626",
    mes: "Noviembre",
    contenido: [
      "Procesos catarrales",
      "Manejo del dolor",
      "Salud íntima del hombre (próstata y disfunción)",
    ],
    tituloCurso: null,
    curso: null,
    consejo: ["Diabetes"],
    dia: "Día Mundial de la Diabetes",
    fecha: "14 de noviembre",
  },
  {
    id: 11,
    numero: "N.º 627",
    mes: "Diciembre",
    contenido: [
      "Riesgo cardiovascular",
      "Nutrición infantil",
      "Claves en dermofarmacia: Hidratación, maquillaje, flash",
    ],
    tituloCurso: null,
    curso: null,
    consejo: ["Acidez"],
    dia: null,
    fecha: null,
  },
];

export const SlideMagazine = () => {
  const { width } = useScreenSize();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: width >= 992 ? 3 : width >= 768 ? 2 : 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings}>
      {revistas.map((revista) => (
        <CardRevistaTemplate key={revista.id}>
         
          <section className="rev__content">
          <div className="rev__number">{revista.numero}</div>
          <section className="p-3">

            <div className="rev__content-mes my-2">{revista.mes}</div>

            <div className="rev__content-text">
              <h6>Contenido revista:</h6>
              <ul>
                {revista.contenido.map((list) => (
                  <li>{list}</li>
                ))}
              </ul>
              {revista.tituloCurso !== null && (
                <div className="rev__box my-3">
                  <div className="fw-bold">{revista.tituloCurso}</div>
                  <div>{revista.curso}</div>
                </div>
              )}
              <h6>Tu consejo:</h6>

              <ul>
                {revista.consejo.map((cons, index) => (
                  <li key={index}>{cons}</li>
                ))}
              </ul>
            </div>
          </section>
          </section>
          <section className="rev__destacado">
            {revista.dia !== null && (
              <>
                <div className="rev__day">{revista.dia}</div>
                <div>{revista.fecha}</div>
                <div className="fs-6">Reportaje interactivo on-line</div>
              </>
            )}
          </section>
        </CardRevistaTemplate>
      ))}
    </Slider>
  );
};
