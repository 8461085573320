import { useState, useRef } from "react";
import { BtnNotoriedad } from "./BtnNotoriedad";
import { SectionTemplate } from "./SectionTemplate";
import { motion } from "framer-motion";
import useScreenSize from "../hooks/useScreenSize";
const variants = {
  normal: {
    x: 0,
    transition: {
      duration: 2,
    },
  },
  graphic: {
    x: "-82%",
    transition: {
      duration: 1,
    },
  },
  none: {
    scaleY: 0,
    display: "none",
    transition: {
      duration: 1,
    },
  },
  block: {
    scaleY: 1,
    display: "block",
    transition: {
      duration: 1,
    },
  },

  hideButton: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
  showButton: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

export const Notoriedad = () => {
  const refGraph = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [graph, setGraph] = useState(null);
  const { width } = useScreenSize();

  const handleClick = (graphic) => {
    // setIsOpen((isOpen) => !isOpen);
    setIsOpen(true);
    setGraph(graphic);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div id="notoriedad"></div>
      <SectionTemplate
        title="NOTORIEDAD"
        direction="left"
        textDirection="text-start"
      >
        <motion.button
          animate={isOpen ? "showButton" : "hideButton"}
          variants={variants}
          className="close"
          onClick={handleClose}
        >
          <img
            src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/pico-left-red.svg"
            alt=""
          />
        </motion.button>
        <motion.section
          animate={width >= 992 && isOpen ? "graphic" : "normal"}
          variants={variants}
          className="row position-relative"
        >
          <article className="col-lg-6 ">
            <h2>Si tu objetivo es la</h2>
            <h3>notoriedad de marca…</h3>
            <p className="text__p mt-4">
              Elige el formato que mejor se ajuste a tu plan de comunicación o
              consulta por los packs anuales con condiciones especiales
            </p>
          </article>
          <div className="col-lg-6  d-flex flex-lg-column justify-content-around align-items-end">
            <BtnNotoriedad
              text="Publicidad impresa"
              clasBtn="btn__impresa"
              click={() => handleClick("taula_01.png")}
            />
            <BtnNotoriedad
              text="Publicidad web"
              clasBtn="btn__web"
              click={() => handleClick("taula_02.png")}
            />
            <BtnNotoriedad
              text="Otros espacios"
              clasBtn="btn__otros"
              click={() => handleClick("taula_03.png")}
            />
          </div>
          <motion.div
            ref={refGraph}
            animate={
              width >= 992
                ? isOpen
                  ? "showButton"
                  : "hideButton"
                : isOpen
                ? "block"
                : "none"
            }
            variants={variants}
            className="box__graph mt-5 mt-lg-0"
          >
            {graph !== null && (
              <img
                src={`https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/${graph}`}
                width="600"
                className="img-fluid"
                alt="Grafica"
              />
            )}
          </motion.div>
        </motion.section>
      </SectionTemplate>
    </>
  );
};
