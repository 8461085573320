import { CardTemplate } from "./CardTemplate";
import { PerfilesModal } from "./PerfilesModal";

export const Cifras = () => {
  return (
    <>
      <section className="container text-center mt-5">
        <h3>Algunas cifras que justifican</h3>
        <h2>por qué invertir…</h2>
      </section>
      <section className="bg__box-cards mt-5">
        <div className="container ">
          <div className="row">
            <CardTemplate colSize="4">
              Con <div className="destacado">39</div> años de experiencia y más
              de <div className="destacado">600</div> números publicados
            </CardTemplate>
            <CardTemplate colSize="4">
              Llegamos mensualmente a <div className="destacado">20 300</div>{" "}
              farmacias
              <div className="fw-bold fs-5">(Control OJD)</div>
            </CardTemplate>
            <CardTemplate colSize="4">
              <div className="destacado">+ 1 600 000</div>
              sesiones web anuales
              <div className="fw-bold fs-5">(+7 % vs. 2021)</div>
            </CardTemplate>
            <CardTemplate colSize="4">
            Tasa de apertura Newsletter <div className="destacado">20%</div>
            <div className="fw-bold fs-5">(+5 % vs. 2021)</div>
            </CardTemplate>
            <CardTemplate colSize="4">
             <div className="destacado">+ 30 500</div>{" "}
             seguidores en RR.SS. 
              <div className="fw-bold fs-5">(+9 % vs. 2021)</div>
            </CardTemplate>
            <CardTemplate colSize="4">
              <div className="destacado">+ 16 500</div>
              direcciones de mail 
              <div className="fw-bold fs-5">(+9 % vs. 2021)</div>
            </CardTemplate>
          </div>
          <section className=" ">
            <article className="d-flex  justify-content-center align-items-center">
              <img
                src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/logo_web_medica.svg"
                alt="Web médica acreditada"
                className="img-fluid"
              />
              <div className="ms-2 font-small">
                Única web farmacéutica acreditada por el Colegio de Médicos de
                Barcelona, que garantiza un contenido de confianza y calidad al
                usuario
              </div>
            </article>
            <div className="text-center my-3">
              <PerfilesModal />
            </div>
          </section>
        </div>
      </section>
    </>
  );
};
