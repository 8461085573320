import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../styles/navigation.css";

export const Navigation = () => {
  return (
    <Navbar expand="md">
      <Container>
        <Navbar.Brand href="#home">
          <img
            src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/logoBola.svg"
            alt="El farmacéutico"
            width="100"
            className="ball"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="">
            <Nav.Link href="#notoriedad">NOTORIEDAD</Nav.Link>
            <Nav.Link href="#posicionamiento"> POSICIONAMIENTO</Nav.Link>
            <Nav.Link href="#relevancia"> RELEVANCIA</Nav.Link>
            <Nav.Link href="#contacto">CONTACTO</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
