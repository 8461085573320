import React from "react";
import { SectionTemplate } from "./SectionTemplate";
import { SlideMagazine } from "./SlideMagazine";

export const Posicionamiento = () => {
  return (
    <>
      <div id="posicionamiento"></div>
      <SectionTemplate
        title="POSICIONAMIENTO"
        direction="right"
        textDirection="text-end"
      >
        <div className="text-end pb-personalizado">
          <h3>Si tu objetivo es ser una</h3>
          <h2>marca referente</h2>
          <h3>en una patología o área de cuidado</h3>
          <p className="text__p mt-4 pb-5">
            Estos son los temas que trataremos en 2023 que te ayudarán a
            posicionarte como experto
          </p>
        </div>
      </SectionTemplate>
      <div className="container margin-negative">
        <SlideMagazine />
      </div>
    </>
  );
};
