import React from "react";
import { SectionTemplate } from "./SectionTemplate";

export const Relevancia = () => {
  return (
    <>
      <div id="relevancia"></div>
      <SectionTemplate
        title="RELEVANCIA"
        direction="left"
        textDirection="text-start"
      >
        <article className="my-5 pb-personalizado">
          <h3>Si tu objetivo es</h3>
          <h2>conectar y ser relevante</h2>
          <h3>para el farmacéutico…</h3>
        </article>
      </SectionTemplate>
      <section className="container margin-negative">
        <div className="row position-relative">
          <div className="col-lg-6">
            <img
              src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/formaciones.png"
              alt=""
              className="img-fluid"
            />
            <p className="text__p col-6 fw-bold mt-4">
              Formaciones de valor añadido para el farmacéutico
            </p>
            <p className="fs-4 col-6">
              <ul>
                <li>Gestión de categorías</li>
                <li>Gestión de equipos </li>
                <li>Venta efectiva</li>
                <li>Categorías en crecimiento</li>
                <li>Atención al paciente</li>
                <li> Casos prácticos</li>
              </ul>
               
            </p>
          </div>
          <div className="col-lg-6 position-relative text-end">
            {/* cambiar ruta imges i mobil per gif animat */}
            <img src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/iPhone.gif" alt="" className="mobil" />
            <img
              src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/farmaceutico.png"
              alt="farmaceutico"
              
              className="img-fluid farmaceutico"
            />
            <p className="text__p col-6 offset-6 fw-bold mt-4 text-end">
              Acciones junto con KOL farmacéuticos
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
