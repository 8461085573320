import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";


export const PackTematico = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <section className="container text-center mb-5 mt-4">
      <Button variant="secondary" onClick={handleShow}>
        Consultar Pack temático
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <section>
            <div className="box__green text-center">
              <div>PACK TEMÁTICO</div>
              <img src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/arrow-green.svg" alt="" className="arrow__green" />
            </div>
            <div className="box__tematic">
              <ul className="list__tematic">
                <li>1 página interior revista</li>
                <li>1 banner mensual</li>
                <li>Presencia en newsletter</li>
                <li>Nota de prensa revista y web</li>
              </ul>
              <div className="text-center mt-4 mb-2">
                <hr />
                <div className="fs-5  mt-4">
                  Consulta condiciones especiales
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </section>
  );
};
