import React from "react";
import { VideoTemplate } from "./VideoTemplate";

export const VideoTop = () => {
  return (
    <>
      <VideoTemplate video="gifinvertiren_v3.webp" />
      <section className="container">
        <h2 className="text-center">es uno de los canales mejor valorados</h2>
        <h3 className="text-center">
          entre los profesionales farmacéuticos y la industria farmacéutica
        </h3>
      </section>
    </>
  );
};
