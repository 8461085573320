import { motion } from "framer-motion";

export const CardRevistaTemplate = ({ children }) => {
  return (
    <section className="d-flex flex-column justify-content-between m-4 ">
      <motion.article
        whileHover={{ scale: 1.1, boxShadow: "0px 0px 3px #A6A6A6" }}
        transition={{ duration: 0.5 }}
        className="box__card-revista d-flex flex-column align-items-center justify-content-between min__card-magazine"
      >
        {children}
      </motion.article>
    </section>
  );
};
