import React from "react";
import { VideoTemplate } from "./VideoTemplate";

export const Ecosistema = () => {
  return (
    <>
      <section className="container mt-5 mb-4 text-center">
        <h3>El Farmacéutico dispone de un</h3>
        <h2>ecosistema digital</h2>
        <h3>
          que nos permite estar al lado del farmacéutico y saber qué necesita
        </h3>
      </section>
      <VideoTemplate video="algoritme_2.webp" />
    </>
  );
};
