import { motion } from "framer-motion";

export const SectionTemplate = ({
  children,
  title,
  direction,
  textDirection,
}) => {
  return (
    <>
      <div className="container mt-5">
        <motion.h4
          whileInView={{ y: [100, 0] }}
          transition={{
            duration: 1,
            ease: "easeInOut",
            times: [0, 1],
          }}
          className={`${textDirection}`}
        >
          {title}
        </motion.h4>
      </div>
      <section className={`bg__section-${direction} mb-5 `}>
        <div className="container bg__cont py-5 position-relative">
          {children}
        </div>
      </section>
    </>
  );
};
