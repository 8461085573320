export const Footer = () => {
  return (
    <footer className="container text-center my-5">
      <img
        src="/logo-foot.svg"
        alt="el Farmacéutico"
        className="img-fluid"
        width="230px"
      />
      <div className="d-flex justify-content-center align-items-center my-4">
        <a
          href="https://www.facebook.com/elfarmaceuticorevista"
          target="_blank" rel="noreferrer"
        >
          <img src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/facebook.svg" alt="facebook" className="mx-1" />
        </a>
        <a href="https://twitter.com/elfarmaceutico" target="_blank" rel="noreferrer">
          <img src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/twiter.svg" alt="twiter" className="mx-1" />
        </a>
        <a
          href="https://www.instagram.com/elfarmaceuticorevista/"
          target="_blank" rel="noreferrer"
        >
          <img src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/insta.svg" alt="instagram" className="mx-1" />
        </a>
        <a
          href="https://www.linkedin.com/company/el-farmac%C3%A9utico/"
          target="_blank" rel="noreferrer"
        >
          <img src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/linkedin.svg" alt="linkedin" className="mx-1" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCZU3oLae-2gq0wtTIcxVYiw"
          target="_blank"  rel="noreferrer"
        >
          <img src="https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/img/youtube.svg" alt="youtube" className="mx-1" />
        </a>
      </div>
    </footer>
  );
};
