import { motion } from "framer-motion";

export const CardTemplate = ({ children, colSize }) => {
  return (
    <section className={`d-flex col-md-${colSize} my-2`}>
      <motion.article
        whileHover={{ scale: 1.1, boxShadow: "0px 0px 3px #A6A6A6" }}
        transition={{ duration: 0.5 }}
        className="box__card d-flex flex-column align-items-center justify-content-around"
      >
        {children}
      </motion.article>
    </section>
  );
};
