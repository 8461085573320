import React from "react";

export const VideoTemplate = ({ video }) => {
  return (
    <div className="box__video container ">
      <img
        src={`https://s3-eu-south-2.ionoscloud.com/elfarmaceutico/assets/${video}`}
        alt=""
        className="img-fluid d-block"
      />
    </div>
  );
};
