import { motion } from "framer-motion";

export const Especiales = () => {
  return (
    <>
      <article className="my-5 text-center">
        <h3>También te podemos ayudar con</h3>
        <h2>acciones especiales</h2>
        <h3>difundidas a través de nuestro canal…</h3>
      </article>

      <section className="box__especiales">
        <div className="container">
          <div className="d-flex justify-content-center flex-wrap">
            <div className="e__box">
              <motion.div
                className="e__box e__box-info"
                animate={{
                  scale: [0, 1],
                }}
                transition={{
                  ease: "easeOut",
                  times: [0, 3],
                  duration: 3,
                  repeat: Infinity,
                  repeatDelay: 2,
                }}
              >
                Infografías
              </motion.div>
            </div>
            <div className="e__box">
              <motion.div
                className="e__box e__box-video"
                animate={{
                  zoom: [0, 1],
                }}
                transition={{
                  ease: "easeOut",
                  times: [0, 3],
                  duration: 3,
                  repeat: Infinity,
                  repeatDelay: 3,
                  delay: 5,
                }}
              >
                Videoanimaciones
              </motion.div>
            </div>
            <div className="e__box">
              <motion.div
                className="e__box e__box-dias"
                animate={{
                  zoom: [0, 1],
                }}
                transition={{
                  ease: "easeOut",
                  times: [0, 3],
                  duration: 3,
                  repeat: Infinity,
                  repeatDelay: 1.5,
                  delay: 2,
                }}
              >
                Días Mundiales
              </motion.div>
            </div>
            <div className="e__box">
              <motion.div
                className="e__box e__box-podcast"
                animate={{
                  zoom: [0, 1],
                }}
                transition={{
                  ease: "easeOut",
                  times: [0, 3],
                  duration: 3,
                  repeat: Infinity,
                  repeatDelay: 2.2,
                  delay: 4,
                }}
              >
                Podcasts
              </motion.div>
            </div>
            <div className="e__box">
              <motion.div
                className="e__box e__box-product"
                animate={{
                  scale: [0, 1],
                }}
                transition={{
                  ease: "easeOut",
                  times: [0, 3],
                  duration: 3.2,
                  repeat: Infinity,
                  repeatDelay: 3,
                  delay: 5,
                }}
              >
                Cuestionarios de conocimiento de producto
              </motion.div>
            </div>
            <div className="e__box">
              <motion.div
                className="e__box e__box-form"
                animate={{
                  zoom: [0, 1],
                }}
                transition={{
                  ease: "easeOut",
                  times: [0, 3],
                  duration: 3,
                  repeat: Infinity,
                  repeatDelay: 2,
                  delay: 7,
                }}
              >
                Formaciones sobre patologías/producto
              </motion.div>
            </div>
            <div className="e__box">
              <motion.div
                className="e__box e__box-guias"
                animate={{
                  scale: [0, 1],
                }}
                transition={{
                  ease: "easeOut",
                  times: [0, 3],
                  duration: 3,
                  repeat: Infinity,
                  repeatDelay: 3,
                  delay: 5,
                }}
              >
                Guías
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
