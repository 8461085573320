import { CardTemplate } from "./CardTemplate";

export const Farmaceutico = () => {
  return (
    <>
      <section className="container text-center my-5">
        <h3 className="porque text-center my-3">¿Por que?</h3>
        <img
          src="/logoFarmaceutico.svg"
          alt="El farmacéutico"
          width="550"
          className="img-fluid"
        />
      </section>
      <section className="bg__box-cards mt-5">
        <div className="container ">
          <div className="row">
            <CardTemplate colSize="3">
              <img src="/territorio.svg" alt="territorio" className="my-3" />
              <p className="text__p my-4">
                <span className="fw-bold color-red me-1">
                  39 años informando y formando
                </span>
                a farmacéuticos de todo el territorio
              </p>
            </CardTemplate>
            <CardTemplate colSize="3">
              <img src="/farmacia.svg" alt="farmacia" className="my-3" />
              <p className="text__p my-4">
                Contenidos elaborados
                <span className="fw-bold color-red ms-1">
                  por farmacéuticos para farmacéuticos
                </span>
              </p>
            </CardTemplate>
            <CardTemplate colSize="3">
              <img src="/rigor.svg" alt="rigor" className="my-3" />
              <p className="text__p my-4">
                <span className="fw-bold color-red">Rigor y calidad</span>
              </p>
            </CardTemplate>
            <CardTemplate colSize="3">
              <img
                src="/grupo.svg"
                alt="grupo mayo"
                className="my-3 img-fluid"
              />
              <p className="text__p my-4">
                Un producto de Grupo Mayo
                <span className="fw-bold color-red mx-1">
                  40 años de experiencia
                </span>
                en el sector sanitario
              </p>
            </CardTemplate>
          </div>
        </div>
      </section>
    </>
  );
};
